<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <ClassInfo :classroom="classroom" :is_show_name="true" :course="course"></ClassInfo>
        </div>
        <div class="col-md-6">
          <ClassInfo :classroom="classroom" :is_show_name="false" :course="course"></ClassInfo>
        </div>
      </div>
      <Header :first-course="course"></Header>
      <div class="row">
        <div class="col-md-6">
          <img :src="`${publicPath}/media/images/center-detail/chitiet.png`" width="100%" height="100%"
               class="highlight-image" alt="">
          <CourseInfo :firstCourse="course"></CourseInfo>
          <LessonContent :firstCourse="course"></LessonContent>
          <TeacherCourse v-if="classroom && classroom.teacher"
                         :teacher="classroom.teacher"
                         :classroom="classroom">
          </TeacherCourse>
          <RateAndComment :ratings="classroom.ratings"></RateAndComment>
        </div>
        <div class="col-md-6 col-xs-12">
          <ContractRegister
              :firstCourse="course"
              :classroom=classroom
              @openDetail="openDetailStudent"
          ></ContractRegister>
        </div>
      </div>
    </div>
    <el-dialog @closed="dialogTableVisible = false" title="Danh sách học viên" :visible.sync="dialogTableVisible">
      <dialog-sudent :students="students"></dialog-sudent>
    </el-dialog>
  </div>

</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import '@/assets/css/tuvanvien.scss';
import {TU_VAN_DETAIL_CLASSROOM} from "../../../../core/services/store/tuVan.module";
import ClassInfo from "./detail-class/ClassInfo";
import Header from "./components/Header";
import CourseInfo from "./components/CourseInfo";
import LessonContent from "./components/LessonContent";
import TeacherCourse from "./components/TeacherCourse";
import RateAndComment from "./components/RateAndComment";
import ContractRegister from "./components/ContractRegister";
import DialogSudent from "./components/DialogSudent";

export default {
  name: "DetailClassroom",
  components: {DialogSudent, ContractRegister, RateAndComment, TeacherCourse, LessonContent, CourseInfo, Header, ClassInfo},
  data() {
    return {
      classroom: null,
      course: null,
      courses: [],
      customer: null,
      gallery: [],
      loading: false,
      publicPath: process.env.VUE_APP_BASE_URL,
      students: {},
      dialogTableVisible: false,
    };
  },
  mounted() {
    this.retrieveCourseDetail();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Chi tiết lớp học", icon: ''}
    ]);
  },
  methods: {
    openDetailStudent(students) {
      this.dialogTableVisible = true;
      this.students = students;
    },
    retrieveCourseDetail() {
      let payload = {
        class_id: this.$route.params.class_id,
        customer_id: this.$route.query.customer_id,
        branch_id: this.$route.query.branch_id,
        type: this.$route.query.type,
      };
      this.loading_course = true;
      this.$store.dispatch(TU_VAN_DETAIL_CLASSROOM, payload)
          .then((response) => {
            if (!response.error) {
              this.classroom = response.data.classroom,
                  this.course = response.data.course,
                  this.courses = response.data.courses,
                  this.customer = response.data.customer,
                  this.gallery = response.data.gallery
            }
            this.loading_course = false;
          });
    }
  },
}
</script>

<style>
.highlight-image {
  height: 450px;
}
</style>