<template>
  <el-card class="box-card mt-3" v-if="classroom">
    <div class="detail-classroom mt-2">
      <h1 v-if="is_show_name" class="bold">{{ classroom.name }}</h1>
      <div v-else>
        <h3 class="price">{{ course.fee |formatVND }}</h3>
        <div v-if="classroom && classroom.number_student">
          còn {{ classroom.number_student - classroom.current_student }} suất
        </div>
        <h1 class="bold">{{ classroom.name }}</h1>
      </div>
    </div>
    <div class="detail-classroom mt-2">
      <img class="mr-2" :src="`${publicPath}/media/images/center-detail/opening-times.svg`" width="22" height="22"
           alt="">
      <span>{{ classroom.start_date | formatDate }}</span>
    </div>
    <div class="detail-classroom mt-2">
      <img class="mr-2" :src="`${publicPath}/media/images/center-detail/user_amount.svg`" width="22" height="22"
           alt="">
      <span>
          <el-button type="text">{{ classroom.current_student }}</el-button>
        <span v-if="classroom.type != 'trial'">/{{ classroom.number_student }}</span> học viên
      </span>
    </div>
    <div class="detail-classroom mt-2">
      <img class="mr-2" :src="`${publicPath}/media/images/center-detail/time.svg`" width="22" height="22" alt="">
      <span>{{
          classroom.schedules.length ? `${classroom.schedules[0].start_date}` + '-' + `${classroom.schedules[0].end_date}` + ' ' + `${classroom.schedules[0].day}` : ''
        }}</span>
    </div>
    <div class="detail-classroom mt-2">
      <img class="mr-2" :src="`${publicPath}/media/images/center-detail/video.svg`" width="22" height="22" alt="">
      <span v-if="classroom.number_lesson">{{ classroom.number_lesson }} Buổi</span>
    </div>
    <div class="detail-classroom mt-2">
      <img class="mr-2" :src="`${publicPath}/media/images/center-detail/research-center.svg`" width="22"
           height="22" alt="">
      <router-link v-if="classroom.branch && classroom.branch.center"
                   :to="{ path: '/detail-center/'+ classroom.branch.center.id }"
                   style="text-decoration: unset"
      >{{ classroom.branch.center.name + ':' + classroom.branch.center.address }}
      </router-link>
    </div>
    <div class="detail-classroom mt-2">
      <img class="mr-2" :src="`${publicPath}/media/images/center-detail/position-marker.svg`" width="22"
           height="22" alt="">
      <span>{{ classroom.branch ? classroom.branch.name : '' }}</span>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "ClassInfo",
  components: {},
  props: {
    classroom: {
      type: Object,
      default: () => {
        return {};
      }
    },
    is_show_name: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    course: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
    }
  },

}
</script>

<style>
.price {
  color: red;
  font-weight: bold;
  font-size: 30px;
}
</style>